<!--
 * @Author: your name
 * @Date: 2020-08-18 16:58:06
 * @LastEditTime: 2020-08-18 16:58:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kysystemd:\source\web\src\views\modules\productFlow\locker\index.vue
-->
<template>
  <div class="wrapper">
      储物柜管理
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style lang="less" scoped>
.wrapper{}
</style>